import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout/home'
import { ProductRangeBlock } from '/src/components/commerce'
import { NewsListBlock } from '/src/components/news'
 // import { HomeFooterListBlock } from '/src/components/common'

const IndexPage = () => {
  return (
    <Layout>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center justify-content-center">
            <ProductRangeBlock />
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-4">
        <Col xs={10}>
          <Row className="d-flex align-items-center justify-content-center">
            <NewsListBlock />
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage

export const Head = () => {
  return (
    <>
      <title>Home | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}
